import React,{useState} from 'react'
import {Header} from "../Header"
import {Menu} from "../Menu";
import { useLocation } from 'react-router-dom';

import {CreateAdmin} from "./CreateAdmin"
export function CreateAdminDetails() {
    const location = useLocation();
    var adminData = location.state && location.state.adminData;
    const [loc,setLoc] = useState(window.location.pathname);
    return (
        <div>
            <Header />
            <div className="d-flex">
            <Menu pageTitle={loc} />
            <CreateAdmin adminData={adminData}/>
        </div>
        </div>
    )
}
