import React, { useState, useEffect, useMemo } from "react";
import JobFilterComponent from "./JobFilterComponent";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import StateAndCities from "../State and Cities/StateAndCities.json"
import SummerNote from "../Summer Note/SummerNote";


const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export function Job(props) {
  $(document).ready(function () {
    $("ul.tabs li").click(function () {
      var tab_id = $(this).attr("data-tab");

      $("ul.tabs li").removeClass("current");
      $(".tab-content").removeClass("current");

      $(this).addClass("current");
      $("#" + tab_id).addClass("current");
    });
  });

  const token = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  const history = useHistory();

  var todayy = "";

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [uniData, setUniData] = useState([]);
  const [jobState, setJobState] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [allJobList, setAllJobList] = useState([]);
  const [categoryWiseData, setCategoryWiseData] = useState([])
  const [jobId, setJobId] = useState("");
  const [catId, setCatId] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [emptyStatus, setEmptyStatus] = useState(false);


  // Edit Form Fields
  const [editJobId, setEditJobId] = useState("")
  const [editJobCategory, setEditJobCategory] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobBudget, setJobBudget] = useState("");
  const [companyLogo, setCompanyLogo] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [university, setUniversity] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endtDateTime, setEndtDateTime] = useState("");
  const [publishDateTime, setPublishDateTime] = useState("");
  const [expiryDateTime, setExpiryDateTime] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");

  const [stateCityData, setStateCityData] = useState([StateAndCities]);
  const [stateNameData, setStateNameData] = useState([]);
  const [cityNameData, setCityNameData] = useState([])

  const [showFullContent, setShowFullContent] = useState(false);

  const [loading, setLoading] = useState(true); // Loader state

  const toggleContent = (index) => {
    setShowFullContent((prevShowFullContent) => {
      const newShowFullContent = [...prevShowFullContent];
      newShowFullContent[index] = !newShowFullContent[index];
      return newShowFullContent;
    });
  };


  async function fetchList() {
    let isMounted = true;
    try {
      setLoading(true); // Start loading

      const fetchResponse = await axios.get(
        process.env.REACT_APP_API_KEY + "super_admin_get_job_list",
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: token,
          },
        }
      );

      if (fetchResponse.data && fetchResponse.data.data) {
        const JobList = fetchResponse.data.data || [];
        setAllJobList(JobList);
        setCategoryWiseData(JobList);

        const errorcode = fetchResponse.data.error_code;

        // If error code is 200, update the state
        if (errorcode === 200 && isMounted) {
          setData(JobList);
          setShowFullContent(new Array(JobList.length).fill(false));
        } else {
          // Handle API error if error code is not 200
          console.error("API Error Code:", errorcode);
        }
      } else {
        console.error("Invalid response data:", fetchResponse.data);
      }
    } catch (err) {
      console.error("Error during API call:", err);
    } finally {
      if (isMounted) {
        setLoading(false); // Hide loading after API call completes
      }
    }
  }



  // console.log("super_admin_get_job_list------",fetchResponse);
  //     const JobList = fetchResponse.data.data;
  //     setAllJobList(fetchResponse.data.data);
  //     setCategoryWiseData(fetchResponse.data.data);
  //     const errorcode = fetchResponse.data.error_code;

  //     if (errorcode == 200) {
  //       setData(JobList);
  //       setShowFullContent(new Array(JobList.length).fill(false));
  //     }
  //   } catch (err) {
  //     console.log("Log in Fail", err);
  //   } finally {
  //     setLoading(false); // Hide loader after API call
  //   }
  // }

  const remoteJobs = (c_id) => {
    $(".edit_campus_modal").hide();
    setCatId(c_id);
    if (c_id == 6) {
      fetchList();
      setEmptyStatus(false);
    } else {
      const filteredData = allJobList.filter((item) => item.category == c_id);
      $(".edit_campus_modal").hide();
      setCategoryWiseData(filteredData);
      if (filteredData == "") {
        setEmptyStatus(true);
      } else {
        setEmptyStatus(false);
      }
    }
  }

  // async function deleteJob() {
  //   try {

  //     const formData = new FormData();
  //     formData.append("job_id", jobId);
  //     const StatusResponse = await axios.post(
  //       process.env.REACT_APP_API_KEY + "super_admin_delete_job",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",

  //           Authorization: token,
  //         },
  //       }
  //     );

  //     // console.log("delete jobresponce----------",StatusResponse);
  //     if (StatusResponse.data.error_code == 200) {

  //       $(".delete_job_modal").removeClass("showModal");
  //       setErrorMsg(StatusResponse.data.message);

  //       $(".formSuccess").show()
  //       setTimeout(() => {
  //         $(".formSuccess").hide()
  //       }, 3000);

  //       fetchList();
  //     }
  //   } catch (err) {
  //     console.log("Log in Fail", err);
  //   }
  // }
  async function deleteJob() {
    try {
      const formData = new FormData();
      formData.append("job_id", jobId);

      const StatusResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_delete_job",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (StatusResponse.data.error_code === 200) {
        // Hide modal and show success message
        $(".delete_job_modal").removeClass("showModal");
        setErrorMsg(StatusResponse.data.message);

        $(".formSuccess").show();
        setTimeout(() => {
          $(".formSuccess").hide();
        }, 3000);

        fetchList(); // Refresh job list after deletion
      } else {
        // Handle failure if error code is not 200
        setErrorMsg(StatusResponse.data.message);
      }
    } catch (err) {
      console.log("Error in deleteJob:", err);
      setErrorMsg("An error occurred while deleting the job.");
    }
  }


  function delete_job(job_id) {
    setJobId(job_id);
    $(".delete_job_modal").addClass("showModal");
  }

  function closeModal() {
    setJobId("");
    $(".delete_job_modal").removeClass("showModal");
  }

  const getCityData = async (e) => {
    setState(e)
    stateNameData.map((_e) => {
      if (_e.state == e) {
        setCityNameData(_e.districts);
      }
    })
    try {
      const formData = new FormData();
      formData.append("state_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_state_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const getCityAndUniData = async (e) => {
    setUniData([]);
    setCity(e)
    try {
      const formData = new FormData();
      formData.append("city_name", e);

      const fetchResponse = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_get_university_list_city_wise",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      // console.log("uni Response---------------------",fetchResponse);

      if (fetchResponse.data.error_code == 200) {
        setUniData(fetchResponse.data.data);
      } else {
        setUniData([]);
      }
    } catch (err) {
      console.log("Log in Fail", err);
    }
  };

  const stateFun = () => {

    setStateCityData(StateAndCities)
    stateCityData.map((e) => {
      setStateNameData(e.states);
    })
  }

  useEffect(() => {
    fetchList();
    stateFun();
  }, []);



  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = categoryWiseData.length > 0 ? categoryWiseData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  ) : [];


  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <JobFilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [jId, setjId] = useState("");
  const openActionsModal = (j) => {
    setjId(j);
    $(".edit_campus_modal").hide();
    $(".actions_modal" + j).toggle();
  }
  const closeActionsModal = () => {
    $(".edit_campus_modal").hide();
  }
  const showJobPreview = (j_id) => {
    history.push("/jobpreview", { j_id })
  }

  const handelSummenrnote = (e) => {
    setJobDescription(e);
  };

  const saveJob = async () => {
    try {

      if (editJobCategory == "") {
        $(".job_category").show();

        setTimeout(function () {
          $(".job_category").hide();
        }, 3000);
      } else if (jobTitle == "") {
        $(".job_title").show();

        setTimeout(function () {
          $(".job_title").hide();
        }, 3000);
      }
      else if (companyName == "") {
        $(".company_name").show();

        setTimeout(function () {
          $(".company_name").hide();
        }, 3000);
      }
      else if (jobDescription == "") {
        $(".job_description").show();

        setTimeout(function () {
          $(".job_description").hide();
        }, 3000);
      }
      else if (companyLogo == "") {
        $(".comp_logo").show();

        setTimeout(function () {
          $(".comp_logo").hide();
        }, 3000);
      }
      else if (state == "") {
        $(".job_state").show();

        setTimeout(function () {
          $(".job_state").hide();
        }, 3000);
      }
      else if (jobLocation == "") {
        $(".job_location").show();

        setTimeout(function () {
          $(".job_location").hide();
        }, 3000);
      }
      else if (startDateTime == "") {
        $(".start_date").show();

        setTimeout(function () {
          $(".start_date").hide();
        }, 3000);
      } else if (endtDateTime == "") {
        $(".end_date").show();

        setTimeout(function () {
          $(".end_date").hide();
        }, 3000);
      }
      else if (publishDateTime == "") {
        $(".publish_date").show();

        setTimeout(function () {
          $(".publish_date").hide();
        }, 3000);
      }
      else if (expiryDateTime == "") {
        $(".expiry_date").show();

        setTimeout(function () {
          $(".expiry_date").hide();
        }, 3000);
      }
      else if (companyEmail == "") {
        $(".comp_email").show();

        setTimeout(function () {
          $(".comp_email").hide();
        }, 3000);
      }
      else if (jobBudget == "") {
        $(".job_budget").show();

        setTimeout(function () {
          $(".job_budget").hide();
        }, 3000);
      } else {

        const formData = new FormData();
        formData.append("job_id", editJobId);
        formData.append("category", editJobCategory);
        formData.append("company_name", companyName);
        formData.append("job_title", jobTitle);
        formData.append("description", jobDescription);
        formData.append("budget", jobBudget);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("university", university);
        formData.append("start_date_time", startDateTime);
        formData.append("end_date_time", endtDateTime);
        formData.append("publish_start_date_time", publishDateTime);
        formData.append("publish_end_date_time", expiryDateTime);
        formData.append("comp_email", companyEmail);
        formData.append("job_location", jobLocation);

        for (let i = 0; i < companyLogo.length; i++) {
          formData.append("comp_logo", companyLogo[i]);
        }

        const fetchResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_admin_edit_job_list",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        );
        // console.log("job edit response-----------",fetchResponse);
        if (fetchResponse.data.error_code == 200) {
          $(".preview_category").hide();
          fetchList();
        }
      }
    } catch (err) {
      console.log("job edit error----------", err);
    }
  }

  function openEditForm(r_id) {
    setEditJobId(r_id);
    setCompanyLogo("");
    $(".edit_campus_modal").hide();
    const edit_data = categoryWiseData.filter((item) => item.job_id == r_id);
    edit_data.map(async (e) => {
      setCompanyName(e.company_name);
      setEditJobCategory(e.category);
      setJobTitle(e.job_title);
      setCompanyLogo(e.comp_logo)
      setJobDescription(e.description);
      setJobBudget(e.budget);
      setJobLocation(e.job_location);
      setStartDateTime(e.start_date);
      setEndtDateTime(e.Deadline);
      setPublishDateTime(e.publish_start_date_time);
      setExpiryDateTime(e.publish_end_date_time);
      setCompanyEmail(e.comp_email);
      setState(e.state);
      setCity(e.city);
      setUniversity(e.university);
      getCityData(e.state);
      getCityAndUniData(e.city)
      var preview = document.getElementById("file-ip-1-preview");
      preview.style.display = "none";
      $(".image_std").show();

    })

    $(".preview_category").show();
  }

  function closeEditForm() {
    $(".preview_category").hide();
    $(".edit_campus_modal").hide();
    setCompanyLogo("")
  }


  var eventImage = [];
  const getCompLogo = (e) => {
    setCompanyLogo("")
    // $(".image_std").hide();
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        eventImage.push(e.target.files[i]);

        setCompanyLogo(eventImage);

        var src = URL.createObjectURL(e.target.files[i]);
        var preview = document.getElementById("file-ip-1-preview");
        preview.src = src;
        preview.style.display = "block";
        $(".image_std").hide();

      }
    }
  };

  return (

    <div className="content-wrapper">
      <div class="formSuccess" style={{ marginTop: "5px", width: "100%", marginRight: "198px", display: "none" }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="filled" severity="success">
            {errorMsg}
          </Alert>
        </Stack>
      </div>

      {/* <JobFilterComponent />     */}
      <div className="search_box_border_class">
        <div className="col-md-3 d-flex flex-row">
          <div className="search_box_div">
            <img className="search_box_img"
              src={require("../images/Search.png")}

            />
            <Input className="search_box"
              id="search"
              type="text"
              placeholder="Search by job name"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}

            />
          </div>
        </div>

        <div
          className="col-md-9 d-flex flex-row"
          style={{ justifyContent: "end" }}
        >
          <div style={{ marginTop: "0px", padding: "0" }}>
            <Link to="/createJob">
              <button
                type="button"
                className="d-flex  create_button"
                defaultValue="Sign Up"

              >
                <div className="create_button_inner">
                  Create Job
                </div>
                <img className="create_button_img"
                  src="dist/img/Progress.png"

                />
              </button>
            </Link>
          </div>
        </div>


      </div>

      <main className="mt-2">
        <div style={{ borderBottom: "1px solid #D9D9D9" }}>
          <div className="job_Content">
            <div class="tab-container job-tab-container">
              <ul class="tabs job-tabs">
                <li class="tab-link current job-tab-link" data-tab="all"
                  value="6"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  All
                </li>

                <li
                  class="tab-link job-tab-link"
                  data-tab="overview"
                  value="3"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  Remote Friendly
                </li>
                <li
                  class="tab-link job-tab-link"
                  data-tab="features"
                  value="1"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  Part Time
                </li>
                <li
                  class="tab-link job-tab-link"
                  data-tab="screenshots"
                  value="4"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  Freelance
                </li>
                <li
                  class="tab-link job-tab-link"
                  data-tab="faq"
                  value="5"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  Volunteer
                </li>

                <li
                  class="tab-link job-tab-link"
                  data-tab="abc"
                  value="2"
                  onClick={(e) => remoteJobs(e.target.value)}
                >
                  Full Time
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="job_Content" style={{ minHeight: "70vh", maxHeight: "70vh", overflow: "auto" }}>
          <div id="overview" class="tab-content job-tab-content">

            {emptyStatus ? (<>
              <div style={{
                display: "flex", justifyContent: "center",
                fontSize: "12px", fontWeight: "400"
              }}>
                <p>This category has no jobs</p>
              </div>
            </>) : (<>

              {

                filteredItems.map((a_data, index) => {
                  var dateeee = a_data.start_date;
                  var s_date = moment(dateeee).format("D MMM YYYY");
                  var deadline_date = moment(a_data.Deadline).format("D MMM YYYY");

                  return (<>
                    <div>

                      {loading && <p>Loading...</p>}



                      <div>
                        <div
                          style={{
                            background: "#f5f5f5",
                            margin: "20px 10px 0px 10px",
                            padding: "10px",
                            fontSize: "10px",
                          }}
                          className="d-flex"
                        >
                          <div>

                            <div>
                              <img
                                src={a_data.comp_logo}
                                className="job_logo_img"
                              />
                            </div>

                          </div>
                          <div style={{ paddingLeft: "15px", width: "100%" }}>
                            <div
                              className="d-flex"
                              onClick={() => showJobPreview(a_data.job_id)}
                              style={{
                                borderBottom: " 0.5px solid #D9D9D9",
                                cursor: "pointer"
                              }}
                            >
                              <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                                Job Title:
                              </p>{" "}
                              <p style={{ marginLeft: "3px", fontWeight: "600" }}>
                                {a_data.job_title}
                              </p>
                              <p
                                style={{
                                  color: "#2D5DD0",
                                  marginLeft: "100px",
                                  fontWeight: "600",
                                }}
                              >
                                Company Name:
                              </p>
                              <p style={{ fontWeight: "600", marginLeft: "3px" }}>
                                {a_data.company_name}
                              </p>
                              <p style={{ marginLeft: "auto", fontWeight: "600" }}>
                                {s_date}
                              </p>
                            </div>

                            <div style={{ paddingTop: "10PX", width: "50%" }}>
                              <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                                Job Description
                              </p>
                              {/* <p  style={{fontWeight: "500"}}
                    dangerouslySetInnerHTML={{ __html: a_data.description }}
                    ></p> */}
                              1
                              <p
                                className={`desc_class job_desc_limit ${showFullContent[index] ? "show-full-content" : ""
                                  }`}
                                dangerouslySetInnerHTML={{ __html: a_data.description }}
                              ></p>

                              {a_data.description &&
                                a_data.description.length > 350 &&
                                !showFullContent[index] && (
                                  <button
                                    className="job_desc_seemore_btn"
                                    onClick={() => toggleContent(index)}
                                  >
                                    See More..
                                  </button>
                                )}
                              {showFullContent[index] && (
                                <div>
                                  <button
                                    className="job_desc_seemore_btn"
                                    onClick={() => toggleContent(index)}
                                  >
                                    See Less
                                  </button>
                                </div>
                              )}



                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            background: "rgb(196 196 196 / 32%)",
                            margin: "0px 10px 0px 10px",
                            padding: "10px",
                            fontSize: "12px",
                          }}
                        >

                          <div
                            className="d-flex ten_font_class"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="d-flex" style={{ marginLeft: "60px" }}>
                              <div style={{}}>
                                <div style={{ color: "#15A312", textAlign: "center" }}>
                                  Applied
                                </div>
                                <span>{a_data.total_applied}</span>
                              </div>

                              <div style={{ marginLeft: "100px" }}>
                                <div style={{ color: "#2D5DD0", textAlign: "center" }}>
                                  Budget
                                </div>
                                <span>{a_data.budget}</span>
                              </div>

                              <div style={{ marginLeft: "100px" }}>
                                <div style={{ color: "#2D5DD0", textAlign: "center" }}>
                                  Deadline
                                </div>
                                <span>{deadline_date}</span>
                              </div>
                            </div>

                            <div style={{ position: "relative", cursor: "pointer" }}>
                              <button onClick={() => openActionsModal(a_data.job_id)}
                                className="all_action_buttons"
                              >
                                Actions

                              </button>
                              <div
                                class={`edit_campus_modal actions_modal${a_data.job_id}`}
                                id=""
                                style={{
                                  display: "none",
                                  position: "absolute",
                                  top: "5px",
                                  right: "0px"

                                }}
                              >
                                <div className="  ">
                                  <div className=" d-flex ml-auto">
                                    <img
                                      className="campus_img ml-auto"
                                      src="dist/img/Cancel.png"
                                      onClick={closeActionsModal}
                                    />
                                  </div>
                                </div>

                                <div className="  hover_class">
                                  <div className=" d-flex flex-row">
                                    <div>
                                      <img
                                        className="campus_img"
                                        src="dist/img/Chart1.png"
                                      />
                                    </div>
                                    <div className="campus_inner_div">
                                      <span>Stats</span>
                                    </div>
                                  </div>
                                </div>

                                <div className=" d-flex flex-row hover_class">
                                  <div className=" d-flex flex-row" onClick={() => openEditForm(a_data.job_id)}>
                                    <div>
                                      <img
                                        className="campus_img"
                                        src="dist/img/campusEdit.png"
                                      />
                                    </div>
                                    <div className="campus_inner_div">
                                      <span>Edit</span>
                                    </div>
                                  </div>
                                </div>

                                <div className=" d-flex flex-row hover_class">
                                  <div className=" d-flex flex-row" onClick={() => delete_job(a_data.job_id)}>
                                    <div>
                                      <img
                                        className="campus_img"
                                        src="dist/img/campusClose.png"
                                      />
                                    </div>
                                    <div className="campus_inner_div">
                                      <span>Delete</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            {/* <a
                      className="cta"
                      href="#edit"
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "60px",
                      }}
                    >
                      <img
                        src={require("../images/Pencil.png")}

                        alt="edit"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>

                    <a
                      className="cta"
                      href="#deleterow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "2px",
                        }}

                        src={require("../images/delete.png")}
                      />
                      &nbsp;
                    </a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </>)
                })}
            </>)}
            {/* );
          })} */}
          </div>

          {/* <div id="features" class="tab-content job-tab-content">
          <h2 style={{ fontSize: "12px", fontWeight: "500" }}>Part Time</h2>
          {remoteData.map((item) => {
            console.log("get item data", item);
            var created_date = moment(item.created_at).format("D MMM YYYY");
            console.log("print created date", created_date);

            var deadline_date = moment(item.validity).format("D MMM YYYY");
            console.log("print created date", deadline_date);
            return (
              <div>
                <div
                  style={{
                    background: "#f5f5f5",
                    margin: "20px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  className="d-flex"
                >
                  <div style={{ padding: "20px" }}>
                    {item.profile_image == " " ? (
                      <div>
                        <img
                          src={require("../images/no_image.png")}
                          alt="no image"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={item.profile_image}
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>
                    )}

                  </div>
                  <div style={{ padding: "20px 5px 20px 20px", width: "100%" }}>
                    <div
                      className="d-flex"
                      style={{
                        borderBottom: " 0.5px solid rgba(0, 0, 0, 0.5)",
                        paddingBottom: "10px",
                      }}
                    >
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Title:
                      </p>{" "}
                      <p style={{ marginLeft: "3px", fontWeight: "600" }}>
                        {item.job_title}
                      </p>
                      <p
                        style={{
                          color: "#2D5DD0",
                          marginLeft: "100px",
                          fontWeight: "600",
                        }}
                      >
                        Company Name:
                      </p>
                      <p style={{ fontWeight: "600", marginLeft: "3px" }}>
                        {item.company_name}
                      </p>
                      <p style={{ marginLeft: "auto", fontWeight: "600" }}>
                        {created_date}
                      </p>
                    </div>

                    <div style={{ paddingTop: "10PX" }}>
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Description
                      </p>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>

                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "rgb(196 196 196 / 32%)",
                    margin: "0px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                >
                  <div className="d-flex">
                    <div style={{ marginLeft: "auto" }}>
                      <p style={{ color: "#15A312", textAlign: "center" }}>
                        Applied
                      </p>
                      <p>{item.apply_job_count}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Budget
                      </p>
                      <p>{item.budget}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Deadline
                      </p>
                      <p>{deadline_date}</p>
                    </div>

                    <a
                      className="cta"
                      href="#edit"
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "60px",
                      }}
                    >
                      <img
                        src={require("../images/Pencil.png")}
                        onClick={() => editJob(item.job_id)}
                        alt="edit"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>

                    <a
                      className="cta"
                      href="#deleterow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "2px",
                        }}
                        onClick={() => deleteJob(item.job_id, item.job_title)}
                        src={require("../images/delete.png")}
                      />
                      &nbsp;
                    </a>


                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

          {/* <div id="screenshots" class="tab-content job-tab-content">
          <h2 style={{ fontSize: "12px", fontWeight: "500" }}>Freelance</h2>
          {remoteData.map((item) => {
            console.log("get item data", item);
            var created_date = moment(item.created_at).format("D MMM YYYY");
            console.log("print created date", created_date);

            var deadline_date = moment(item.validity).format("D MMM YYYY");
            console.log("print created date", deadline_date);
            return (
              <div>
                <div
                  style={{
                    background: "#f5f5f5",
                    margin: "20px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  className="d-flex"
                >
                  <div style={{ padding: "20px" }}>
                    {item.profile_image == " " ? (
                      <div>
                        <img
                          src={require("../images/no_image.png")}
                          alt="no image"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={item.profile_image}
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>
                    )}

                  </div>
                  <div style={{ padding: "20px 5px 20px 20px", width: "100%" }}>
                    <div
                      className="d-flex"
                      style={{
                        borderBottom: " 0.5px solid rgba(0, 0, 0, 0.5)",
                        paddingBottom: "10px",
                      }}
                    >
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Title:
                      </p>{" "}
                      <p style={{ marginLeft: "3px", fontWeight: "600" }}>
                        {item.job_title}
                      </p>
                      <p
                        style={{
                          color: "#2D5DD0",
                          marginLeft: "100px",
                          fontWeight: "600",
                        }}
                      >
                        Company Name:
                      </p>
                      <p style={{ fontWeight: "600", marginLeft: "3px" }}>
                        {item.company_name}
                      </p>
                      <p style={{ marginLeft: "auto", fontWeight: "600" }}>
                        {created_date}
                      </p>
                    </div>

                    <div style={{ paddingTop: "10PX" }}>
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Description
                      </p>

                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "rgb(196 196 196 / 32%)",
                    margin: "0px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                >
                  <div className="d-flex">
                    <div style={{ marginLeft: "auto" }}>
                      <p style={{ color: "#15A312", textAlign: "center" }}>
                        Applied
                      </p>
                      <p>{item.apply_job_count}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Budget
                      </p>
                      <p>{item.budget}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Deadline
                      </p>
                      <p>{deadline_date}</p>
                    </div>

                    <a
                      className="cta"
                      href="#edit"
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "60px",
                      }}
                    >
                      <img
                        src={require("../images/Pencil.png")}
                        onClick={() => editJob(item.job_id)}
                        alt="edit"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>

                    <a
                      className="cta"
                      href="#deleterow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "2px",
                        }}
                        onClick={() => deleteJob(item.job_id, item.job_title)}
                        src={require("../images/delete.png")}
                      />
                      &nbsp;
                    </a>


                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

          {/* <div id="faq" class="tab-content job-tab-content">
          <h2 style={{ fontSize: "12px", fontWeight: "500" }}>Volunteer</h2>
          {remoteData.map((item) => {
            console.log("get item data", item);
            var created_date = moment(item.created_at).format("D MMM YYYY");
            console.log("print created date", created_date);

            var deadline_date = moment(item.validity).format("D MMM YYYY");
            console.log("print created date", deadline_date);
            return (
              <div>
                <div
                  style={{
                    background: "#f5f5f5",
                    margin: "20px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  className="d-flex"
                >
                  <div style={{ padding: "20px" }}>
                    {item.profile_image == " " ? (
                      <div>
                        <img
                          src={require("../images/no_image.png")}
                          alt="no image"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={item.profile_image}
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>
                    )}

                  </div>
                  <div style={{ padding: "20px 5px 20px 20px", width: "100%" }}>
                    <div
                      className="d-flex"
                      style={{
                        borderBottom: " 0.5px solid rgba(0, 0, 0, 0.5)",
                        paddingBottom: "10px",
                      }}
                    >
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Title:
                      </p>{" "}
                      <p style={{ marginLeft: "3px", fontWeight: "600" }}>
                        {item.job_title}
                      </p>
                      <p
                        style={{
                          color: "#2D5DD0",
                          marginLeft: "100px",
                          fontWeight: "600",
                        }}
                      >
                        Company Name:
                      </p>
                      <p style={{ fontWeight: "600", marginLeft: "3px" }}>
                        {item.company_name}
                      </p>
                      <p style={{ marginLeft: "auto", fontWeight: "600" }}>
                        {created_date}
                      </p>
                    </div>

                    <div style={{ paddingTop: "10PX" }}>
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Description
                      </p>

                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "rgb(196 196 196 / 32%)",
                    margin: "0px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                >
                  <div className="d-flex">
                    <div style={{ marginLeft: "auto" }}>
                      <p style={{ color: "#15A312", textAlign: "center" }}>
                        Applied
                      </p>
                      <p>{item.apply_job_count}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Budget
                      </p>
                      <p>{item.budget}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Deadline
                      </p>
                      <p>{deadline_date}</p>
                    </div>

                    <a
                      className="cta"
                      href="#edit"
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "60px",
                      }}
                    >
                      <img
                        src={require("../images/Pencil.png")}
                        onClick={() => editJob(item.job_id)}
                        alt="edit"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>

                    <a
                      className="cta"
                      href="#deleterow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "2px",
                        }}
                        onClick={() => deleteJob(item.job_id, item.job_title)}
                        src={require("../images/delete.png")}
                      />
                      &nbsp;
                    </a>


                  </div>
                </div>
              </div>
            );
          })}
        </div> */}

          {/* <div id="abc" class="tab-content job-tab-content">
          <h2 style={{ fontSize: "12px", fontWeight: "500" }}>Full Time</h2>
          {remoteData.map((item) => {
            console.log("get item data", item);
            var created_date = moment(item.created_at).format("D MMM YYYY");
            console.log("print created date", created_date);

            var deadline_date = moment(item.validity).format("D MMM YYYY");
            console.log("print created date", deadline_date);
            return (
              <div>
                <div
                  style={{
                    background: "#f5f5f5",
                    margin: "20px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                  className="d-flex"
                >
                  <div style={{ padding: "20px" }}>
                    {item.profile_image == " " ? (
                      <div>
                        <img
                          src={require("../images/no_image.png")}
                          alt="no image"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={item.profile_image}
                          style={{ height: "100px", width: "100px" }}
                        />
                      </div>
                    )}

                  </div>
                  <div style={{ padding: "20px 5px 20px 20px", width: "100%" }}>
                    <div
                      className="d-flex"
                      style={{
                        borderBottom: " 0.5px solid rgba(0, 0, 0, 0.5)",
                        paddingBottom: "10px",
                      }}
                    >
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Title:
                      </p>{" "}
                      <p style={{ marginLeft: "3px", fontWeight: "600" }}>
                        {item.job_title}
                      </p>
                      <p
                        style={{
                          color: "#2D5DD0",
                          marginLeft: "100px",
                          fontWeight: "600",
                        }}
                      >
                        Company Name:
                      </p>
                      <p style={{ fontWeight: "600", marginLeft: "3px" }}>
                        {item.company_name}
                      </p>
                      <p style={{ marginLeft: "auto", fontWeight: "600" }}>
                        {created_date}
                      </p>
                    </div>

                    <div style={{ paddingTop: "10PX" }}>
                      <p style={{ color: "#2D5DD0", fontWeight: "600" }}>
                        Job Description
                      </p>

                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    background: "rgb(196 196 196 / 32%)",
                    margin: "0px 10px 0px 10px",
                    padding: "10px",
                    fontSize: "12px",
                  }}
                >
                  <div className="d-flex">
                    <div style={{ marginLeft: "auto" }}>
                      <p style={{ color: "#15A312", textAlign: "center" }}>
                        Applied
                      </p>
                      <p>{item.apply_job_count}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Budget
                      </p>
                      <p>{item.budget}</p>
                    </div>

                    <div style={{ marginLeft: "60px" }}>
                      <p style={{ color: "#2D5DD0", textAlign: "center" }}>
                        Deadline
                      </p>
                      <p>{deadline_date}</p>
                    </div>

                    <a
                      className="cta"
                      href="#edit"
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "60px",
                      }}
                    >
                      <img
                        src={require("../images/Pencil.png")}
                        onClick={() => editJob(item.job_id)}
                        alt="edit"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </a>

                    <a
                      className="cta"
                      href="#deleterow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "2px",
                        }}
                        onClick={() => deleteJob(item.job_id, item.job_title)}
                        src={require("../images/delete.png")}
                      />
                      &nbsp;
                    </a>


                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
        </div>
      </main>

      <div
        id="delete"
        className="modaloverlay delete_container delete_job_modal"
      >
        <div className="modalContainer">
          <div className="card-body" style={{ marginTop: "0px" }}>
            <div>
              <p
                style={{ fontWeight: "600", color: "black", fontSize: "13px" }}
              >
                Delete Job
              </p>
              <h2 style={{ marginTop: "20PX", fontSize: "13PX" }}>
                Are You Sure, You Want To Delete this Job?
              </h2>

              <div className="d-flex mt-3">
                <a
                  //   onClick={close_delete_modal}
                  href="#"
                  style={{ marginLeft: "auto" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    value="Cancel"
                    onClick={() => closeModal()}
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#c4c4c4",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>

                <a
                  href="#delete_with_protection"
                  style={{ color: "grey", fontSize: "15PX" }}
                >
                  <input
                    type="button"
                    className="create_btn"
                    id="delete_single_city"
                    value="Delete"
                    onClick={() => deleteJob()}
                    style={{
                      borderRadius: "5px",
                      marginRight: "7px",
                      backgroundColor: "#d21f3c",
                      fontSize: "13PX",
                      padding: "8px 12px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
      {/* Modal end */}

      {/* **************************** Edit Job ***************************** */}
      <div
        className="preview_category">
        <div className="edit_inner">
          <div className="d-flex edit_inner_div">
            <label className="main_labels">
              Job
            </label>

            <img
              src="dist/img/Cancel.png"
              alt="dropdown"
              className="close_event ml-auto cancel_img"
              onClick={closeEditForm}

            />
          </div>

          <div
            className="preview_form">


            <div className="border_class edit_row_padding2">
              <div className=" p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Job Category
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <select
                        className=" edit_all_inputs"
                        id="job_category"
                        aria-label=".form-select-sm example"
                        value={editJobCategory}
                        onChange={(e) => setEditJobCategory(e.target.value)}
                      >
                        <option
                          selected="selected" value=""
                          style={{ padding: "6px" }}
                        >
                          Select Category
                        </option>
                        <option value="1">Part Time</option>
                        <option value="2">Full Time</option>
                        <option value="3">Remote Friendly</option>
                        <option value="4">Freelance</option>
                        <option value="5">Volunteer</option>
                      </select>

                      <div
                        class="job_category"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please enter category
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Job Title
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <input className="edit_all_inputs"
                        type="name"
                        autoComplete="true"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}

                      />

                      <div
                        class="job_title"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please enter job title
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2 p-0">
                  <div class="row edit_row_margin">
                    <div class="col-md-12">
                      <div className="" style={{ width: "100%", marginTop: "0px" }}>
                        <div className="d-flex">
                          <label className="edit_all_labels">
                            Company Name
                          </label>

                          <p className="all_stars">
                            *
                          </p>
                        </div>
                        <input className="edit_all_inputs"
                          type="name"
                          autoComplete="true"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}

                        />

                        <div
                          class="company_name"
                          style={{ marginTop: "-6px", display: "none" }}
                        >
                          <h4
                            class="login-text"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "0",
                            }}
                          >
                            Please enter company name
                          </h4>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>


            </div>

            <div className="border_class edit_row_padding2">
              <div className=" p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-12">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Description
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <SummerNote _onChange={handelSummenrnote}
                        value={jobDescription}
                      />

                      {/* <textarea

                      className=" edit_all_inputs"
                      id="news_description"
                      value={jobDescription}
                      onChange={(e) => setJobDescription(e.target.value)}
                      style={{height:"80px"}}
                    ></textarea>                    */}

                      <div
                        class="job_description"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please Enter Job Description
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="border_class2 edit_row_padding2">
              <div className="p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-12">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Add Company Logo
                        </label>
                      </div>

                      <div>
                        {/* {companyLogo == null ? (
                        <div>
                          <label for="file-ip-1" style={{ height: "100%" }}>
                            <img
                              className="image_std"
                              src={require("../images/no_image.png")}
                              alt="no image"
                              style={{ width: "100px", height: "100px" }}
                            />
                          </label>

                          <input
                            type="file"
                            name="photo"
                            style={{ visibility: "hidden",display:"none" }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={getCompLogo}
                            id="file-ip-1"
                          />
                        </div>
                       ) : (  */}
                        <div>
                          <label
                            for="file-ip-1">
                            <div >
                              <img className="image_std" src={companyLogo}
                                style={{ width: "70px", height: "70px" }} />
                            </div>
                            <img
                              id="file-ip-1-preview"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "6PX",
                                display: "none",
                              }}
                            />
                            <input
                              type="file"
                              name="photo"
                              style={{ visibility: "hidden" }}
                              accept="image/png, image/gif, image/jpeg"
                              onChange={getCompLogo}
                              id="file-ip-1"
                            />
                          </label>
                        </div>
                        {/* )}  */}
                      </div>

                      <div
                        class="comp_logo"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please select company logo
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class2 edit_row_padding2">
              <div className=" p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          State
                        </label>
                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <select
                        className="form-select form-select-sm edit_all_inputs"
                        aria-label=".form-select-sm example"
                        id="department_neww"
                        value={state}
                        onChange={(e) => getCityData(e.target.value)}

                      >
                        <option selected="selected" value="" >
                          Select State
                        </option>
                        {
                          stateNameData.map((e) => {
                            return (
                              <option value={e.state}>{e.state}</option>
                            )
                          })
                        }

                      </select>
                      <div
                        class="job_state"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please select state
                        </h4>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          City
                        </label>
                        <p className="all_stars">

                        </p>

                      </div>
                      <select
                        className="form-select form-select-sm edit_all_inputs"
                        aria-label=".form-select-sm example"
                        id="student_course"
                        value={city}
                        onChange={(e) => getCityAndUniData(e.target.value)}

                      >
                        <option selected="selected" value=""
                          style={{ padding: "6px" }}
                        >
                          Select City
                        </option>

                        {cityNameData.map((name) => {
                          return (
                            <option value={name}>{name}</option>
                          );
                        })}

                      </select>


                    </div>
                  </div>
                </div>
              </div>



              <div className="mt-2 p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          University
                        </label>
                        <p className="all_stars">

                        </p>
                      </div>
                      <select
                        className="form-select form-select-sm edit_all_inputs"
                        aria-label=".form-select-sm example"
                        id="student_class"
                        value={university}
                        onChange={(e) => setUniversity(e.target.value)}

                      >
                        <option selected="selected" value=""
                          style={{ padding: "6px" }}
                        >
                          Select University
                        </option>
                        {uniData.map((e) => {
                          return (
                            <option value={e.uni_id}>{e.uni_name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Location
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="name"
                      className="edit_all_inputs"

                      placeholder="Location"
                      id=""
                      value={jobLocation}
                      onChange={(e) => jobLocation(e.target.value)}
                      name="birthdaytime"

                    />
                    <div
                      class="job_location"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please Write Job Location
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class2 edit_row_padding2">
              <div className=" p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Start Date/Time
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="datetime-local"
                      className="form_control edit_all_inputs"
                      min={todayy + "T00:00"}
                      placeholder="Now"
                      id="publish_date"
                      value={startDateTime}
                      onChange={(e) => setStartDateTime(e.target.value)}
                      name="birthdaytime"

                    />
                    <div
                      class="start_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select start date time
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          End Date/Time
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>

                      <input
                        type="datetime-local"
                        className="form_control edit_all_inputs"
                        min={todayy + "T00:00"}
                        placeholder="dd-mm-yyyy hh-mm"
                        id="publish_date"
                        value={endtDateTime}
                        onChange={(e) => setEndtDateTime(e.target.value)}
                        name="birthdaytime"

                      />
                      <div
                        class="end_date"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please select end date time
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class2 edit_row_padding2">
              <div className=" p-0">
                <div className="mb-2">
                  <label className="edit_all_labels pl-2">
                    When it should be published?
                  </label>
                </div>
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="d-flex">
                      <label className="edit_all_labels">
                        Publish Date/Time
                      </label>

                      <p className="all_stars">
                        *
                      </p>
                    </div>
                    <input
                      type="datetime-local"
                      className="form_control edit_all_inputs"
                      min={todayy + "T00:00"}
                      placeholder="Now"
                      id="publish_date"
                      value={publishDateTime}
                      onChange={(e) => setPublishDateTime(e.target.value)}
                      name="birthdaytime"

                    />

                    <div
                      class="publish_date"
                      style={{ marginTop: "-6px", display: "none" }}
                    >
                      <h4
                        class="login-text"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginLeft: "0",
                        }}
                      >
                        Please select publish date
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Expiry Date/Time
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>

                      <input
                        type="datetime-local"
                        className="form_control edit_all_inputs"
                        min={todayy + "T00:00"}
                        placeholder="dd-mm-yyyy hh-mm"
                        id="publish_date"
                        value={expiryDateTime}
                        onChange={(e) => setExpiryDateTime(e.target.value)}
                        name="birthdaytime"

                      />

                      <div
                        class="expiry_date"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please select expiry date
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border_class2 edit_row_padding2">
              <div className=" p-0">
                <div class="row edit_row_margin">
                  <div class="col-md-6">
                    <div className="" style={{ width: "100%", marginTop: "0px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Company Email
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <input className="edit_all_inputs"
                        type="email"
                        autoComplete="true"
                        value={companyEmail}
                        onChange={(e) => setCompanyEmail(e.target.value)}

                      />

                      <div
                        class="comp_email"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please enter email
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div style={{ paddingLeft: "5px" }}>
                      <div className="d-flex">
                        <label className="edit_all_labels">
                          Budget
                        </label>

                        <p className="all_stars">
                          *
                        </p>
                      </div>
                      <input className="edit_all_inputs"
                        type="number"
                        autoComplete="true"
                        value={jobBudget}
                        onChange={(e) => setJobBudget(e.target.value)}

                      />
                      <div
                        class="job_budget"
                        style={{ marginTop: "-6px", display: "none" }}
                      >
                        <h4
                          class="login-text"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "0",
                          }}
                        >
                          Please enter budget
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ******************button********************** */}

            <div className="d-flex mt-3 edit_buttons_div border_class"
              style={{ justifyContent: "end" }}>
              <input
                type="button"
                className="edit_cancel_button"
                onClick={() => closeEditForm()}
                value="Cancel"

              />

              <input
                type="button"
                className="edit_update_button"
                id="delete_single_student"
                value="Update"
                onClick={() => saveJob()}

              />
            </div>

            <div style={{ display: "none" }} className="saveMessage">
              Data Saved Successfully
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

