import React from 'react'
import {BiPlusMedical,BiSearchAlt2} from "react-icons/bi";
import {AddAdmin} from "./AddAdmin";
export function CreateAdmin({adminData}) {
    return (
      <div className="content-wrapper">

        <div className="border_class2">
            <div style={{ padding: "6px" }}>
              <div className="polls_heading_h1">CREATE NEW CAMPUS</div>
            </div>

            <div className="steps_main_div">
                <div  class="d-flex">
                  <p className="steps_main_div_p">
                    Step 2 :
                  </p>
                  <p className="steps_main_div_inner_p" >
                  Admin Details
                  </p>
                </div>
              </div>
          </div>

{/* news table */}
<AddAdmin adminData={adminData}/>
{/* end news table */}
</div>
    )
}
