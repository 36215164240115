import React, { useState } from "react";
import "../App.css";
import $ from "jquery";
import axios from "axios";
import "../components/AdminLogin.css";
import { Header } from "./Header";
import { useHistory } from "react-router-dom";

export function Login(props) {
  const toggleForm = () => {
    const container = document.querySelector(".container");
    container.classList.toggle("active");
  };

  const [campusId, updateCampusId] = useState("");
  const [password, updatePassword] = useState("");
  const [errorCode, updateErrorCode] = useState("");
  const [errorMessage, updateErrorMessage] = useState("");

  const history = useHistory();

  async function LoginPopUp() {
    //alert("hi, i have been clicked "+campusId+" and "+password)
    try {
      document.getElementById('loader_block').classList.remove("loader--paused");
      const formData = new FormData();
      formData.append("email", campusId);
      formData.append("password", password);
      console.log(password, "password - ", campusId, 'campusId')
      console.log(formData, " - ", process.env.REACT_APP_API_KEY)



      const response = await axios.post(
        process.env.REACT_APP_API_KEY + "super_admin_login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data)

      updateErrorCode(response.data.error_code);
      updateErrorMessage(response.data.message);

      if (response.data.error_code == 200) {
        console.log(response.data.message);
        localStorage.setItem('Token', response.data.data[0].jwt_token);
        localStorage.setItem('active_index', 1);

        $(".myAlert-bottom").show();
        setTimeout(function () {
          history.push("/homepage");
        }, 2000);



      }
      else {
        document.getElementById('loader_block').classList.add("loader--paused");
        $(".failed-bottom").show();
        setTimeout(function () {
          $(".failed-bottom").hide();
        }, 5000);
      }

    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  const [message, updateMessage] = useState("");
  const [adminName, updateAdminName] = useState("");
  const [email, updateEmail] = useState("");
  const [pass, updatePass] = useState("");
  const [mobile, updateMobile] = useState("");
  async function Register() {

    try {
      const admin_name = document.getElementById("reg_name");
      const admin_email = document.getElementById("reg_email");
      const admin_pass = document.getElementById("reg_password");
      const admin_mobile = document.getElementById("reg_mobile");
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (admin_name.value == "" && admin_email.value == "" && admin_pass.value == "" && admin_mobile.value == "") {

        $(".EnterValue").show();

        setTimeout(function () {
          $(".EnterValue").hide();
        }, 3000);
      }
      else if (admin_name.value == "") {

        $(".name").show();

        setTimeout(function () {
          $(".name").hide();
        }, 3000);

      }
      else if (admin_email.value == "") {

        $(".email").show();

        setTimeout(function () {
          $(".email").hide();
        }, 3000);
      }
      else if (!filter.test(admin_email.value)) {

        $(".validEmailAddress").show();

        setTimeout(function () {
          $(".validEmailAddress").hide();
        }, 3000);

      }




      else if (admin_mobile.value == "") {

        $(".mobile").show();

        setTimeout(function () {
          $(".mobile").hide();
        }, 3000);
      }
      else if (admin_pass.value == "") {

        $(".pass_word").show();

        setTimeout(function () {
          $(".pass_word").hide();
        }, 3000);

      }
      else {
        const formData = new FormData();
        formData.append("admin_name", adminName);
        formData.append("email", email);
        formData.append("password", pass);
        formData.append("mobile", mobile);
        const registerResponse = await axios.post(
          process.env.REACT_APP_API_KEY + "super_register",
          formData,
          {
            headers: {
              "Content-Type": "application/json",

            },
          }
        );
        console.log("login", registerResponse);
        updateMessage(registerResponse.data.message)
        console.log("message", registerResponse.data.message);
        $(".register").show();
        setTimeout(function () {
          $(".register").hide();
        }, 5000);

      }

    } catch (err) {
      console.log("Log in Fail", err);
    }
  }

  function togglePassword() {
    var x = document.getElementById("campus_password");
    if (x.type == "password") {
      x.type = "text";

      $(".pass_hide_img").show();
      $(".pass_show_img").hide();
      // document.getElementById("passwordShowHide").innerHTML = "Hide";
    } else {
      x.type = "password";

      $(".pass_hide_img").hide();
      $(".pass_show_img").show();

      // document.getElementById("passwordShowHide").innerHTML = "Show";
    }
  }

  function setCokiee() {
    const u = document.getElementById("campusId").value;

    const p = document.getElementById("campus_password").value;
    document.cookie = "myusername=" + u + ";path=http://localhost:3000/";
    document.cookie = "mypassword=" + p + ";path=http://localhost:3000/";
  }



  return (
    <div >

      <section className="unicircle_login">
        <div className="">
          <div
            className="user signinBx"
            style={{ padding: "0" }}
            id="accountLogin"
          >
            <div className="d-flex" style={{ width: "100%" }}>
              <div
                className="login_empty_div"
              >
                <div class="vertical-line"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.125) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 1px 0px 0px 0px" }}></div>
              </div>
              <div className="login_img_div">
                <img
                  src="dist/img/Group_login_img.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div
                className="border_class2 login_main_div" style={{ boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, .125), 2px 3px 1px 0px rgba(0, 0, 0, .2)" }}>
                <div style={{ padding: "20px" }}>
                  <div>
                    <img
                      src="dist/img/uniLogo.png"
                      style={{ width: "130px", height: "25px" }}
                    />
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    <div>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Super Admin Login
                      </span>
                    </div>
                    <div>
                      <p style={{ fontSize: "12px", fontWeight: "500" }}>
                        Welcome to the control center of UN1CIRCLE –
                        your gateway to seamless administration and powerful oversight.
                      </p>
                    </div>
                  </div>

                  <div id="loader_block" class="loader loader--solid loader--paused mt-3"></div>

                  <div style={{ marginTop: "25px" }}>
                    <div
                      className="d-flex all_inputs"
                      style={{
                        alignItems: "center",
                        border: "1px solid #4779F0",
                      }}
                    >
                      <img
                        src="dist/img/LoginAccount.png"
                        style={{
                          width: "17px",
                          height: "17px",
                          marginLeft: "5px",
                        }}
                      />

                      <input
                        type="email"
                        className=""
                        id="campusId"
                        value={campusId}
                        onChange={(e) => updateCampusId(e.target.value)}
                        placeholder="Super Admin email"
                        autoComplete="off"
                        style={{
                          border: "none",
                          width: "100%",
                          marginLeft: "5px",
                        }}
                      />
                    </div>

                    <div
                      className="d-flex all_inputs mt-2"
                      style={{
                        alignItems: "center",
                        border: "1px solid #4779F0",
                      }}
                    >
                      <img
                        src="dist/img/LoginPassword.png"
                        style={{
                          width: "17px",
                          height: "17px",
                          marginLeft: "5px",
                        }}
                      />
                      <input
                        type="password"
                        className=""
                        placeholder="Password"
                        id="campus_password"
                        value={password}
                        onChange={(e) => updatePassword(e.target.value)}
                        style={{
                          border: "none",
                          width: "100%",
                          marginLeft: "5px",
                        }}
                      />

                      <span
                        className="ml-auto"
                        id="passwordShowHide"
                        onClick={() => togglePassword()}
                        style={{
                          // color: "#2d5dd0",
                          fontSize: "10PX",
                          fontWeight: "500",
                          cursor: "pointer",
                          marginRight: "5px",
                          fontFamily: "Poppins",
                        }}
                      >
                        <img className="pass_show_img" src="dist/img/Eye.png"
                          style={{ height: "23px", width: "30px" }} />

                        <img className="pass_hide_img" src="dist/img/PostHide.png"
                          style={{ height: "23px", width: "30px", display: "none" }} />
                      </span>
                    </div>

                    <div
                      className="d-flex mt-2 "
                      style={{
                        color: "rgba(0, 0, 0, 0.9)",
                        fontSize: "12PX",
                        fontWeight: "600",
                      }}
                    >
                      <p>
                        <input
                          type="checkbox"
                          id="rememberme"
                          name="rememberme"
                          onClick={() => setCokiee()}
                        />
                      </p>
                      <label
                        htmlFor="remember"
                        style={{
                          marginLeft: "4px",
                          marginTop: "2PX",
                          fontSize: "11PX",
                          color: "rgba(0, 0, 0, 0.9)",
                          fontWeight: "600",
                        }}
                      >
                        Remember me
                      </label>
                      <p className="ml-auto">
                        <a
                          href="/forgotpassword"
                          style={{
                            color: "rgba(0, 0, 0, 0.9)",
                            fontSize: "11PX",
                          }}
                        >
                          Forgot Password?
                        </a>
                      </p>
                    </div>

                    <div style={{ marginTop: "40PX" }}>
                      <input
                        type="button"
                        style={{
                          border: "none",
                          background: "#1F3977",
                          fontWeight: "500",
                          color: "white",
                          height: "30px",
                          width: "100%",
                          fontSize: "12PX",
                          textAlign: "center",
                          boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.15)",
                        }}
                        id="loginButton"
                        value="Log in"
                        onClick={() => LoginPopUp()}
                      />
                    </div>

                    <div
                      style={{
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        marginTop: "10px",
                      }}
                    >
                      {errorCode == 200 ? (
                        <div className="d-flex">
                          <img
                            src={require("../Images/correct.png")}
                            style={{ width: "18px" }}
                          />
                          <span style={{ color: "green" }}>{errorMessage}</span>
                        </div>
                      ) : errorCode == 404 ? (
                        <div className="d-flex">
                          <img
                            src={require("../Images/wrong.jpg")}
                            style={{ width: "18px" }}
                          />
                          <span style={{ color: "red" }}>{errorMessage}</span>
                        </div>
                      ) : errorCode == 406 ? (
                        <div className="d-flex">
                          <img
                            src={require("../Images/missing.png")}
                            style={{ width: "15px" }}
                          />
                          <span style={{ color: "red", marginLeft: "5PX" }}>
                            Please! Enter Email address and password
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>








      {/* <section className="unicircle_login" style={{overflowX:"auto"}}>
        <div class="container">
          <div class="user signinBx">
            <div class="formBx" style={{ paddingTop: "0" }}>
              <form
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <div style={{ width: "70%" }}></div>
                  <div width="30%" className="unicircle_img "></div>
                </div>
                <div className="admin_dashboard">
                  <h1>
                    SuperAdmin
                    <br />
                    Dashboard
                  </h1>
                </div>

<div class="myAlert-bottom">
        <div className="success_img ms-3"></div>
        <h4 class="login-text pb-2" style={{textAlign:"center", fontSize:"15px",verticalAlign:"center"}}>You have successfully signed in</h4>
      </div>

      <div class="failed-bottom p-1 lead">
        <div className="fail_img ms-2" ></div>
        <h6 class="loginfailed-text mt-2" style={{font:"15px"}}>Enter Email Id and Password</h6>
      </div>

                <input
                  type="email"
                  name=""
                  placeholder="Email Id"
                  autoComplete="off"
                  id="campusId"
                  value={campusId}
                  required = "1"
                  onChange={(e) => updateCampusId(e.target.value)}
                />
                <input
                  type="password"
                  name=""
                  autoComplete='off'
                  placeholder="Password"
                  id="password"
                  value={password}
                  onChange={(e) => updatePassword(e.target.value)}
                />
                <div className="d-flex" style={{ marginTop: "20px" }}>

                  <div style={{ width: "30%", padding: "0",marginLeft:"AUTO" }}>
                    <input
                      type="button"
                      style={{
                        border: "1px solid #Fd4173",
                        background: "transparent",
                        fontWeight: "bold",
                        color: " #Fd4173",
                        margin: "0",
                        textAlign: "center",
                        padding: "5px 10px",
                      }}
                      id="go"
                      defaultValue="Sign In"
                      value="Log In"
                      onClick={() => LoginPopUp()}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <p
                    style={{
                      color: "white",
                      marginLeft: "auto",
                      fontSize: "13px",
                      marginTop: "5px",
                      fontWeight: "BOLD",
                    }}
                  >
                    <a type="button" href="/forgotpassword" style={{
                      color: "white",
                      marginLeft: "auto",
                      fontSize: "13px",
                      marginTop: "5px",
                      fontWeight: "500",
                      cursor:"pointer"
                    }}>Forgot Password?</a>
                  </p>
                </div>
              </form>
            </div>

            <div class="imgBx">
              <img
                src="dist/img/admin_login.png"
                className="img elevation right_side_img"
                alt="User Image"
              />



              <div
                class="signup d-flex"
                style={{
                  color: "black",
                  marginTop: "5px",
                }}
              >

              </div>
            </div>
          </div>

          <div class="user signupBx">
            <div class="formBx" style={{ paddingTop: "0" }}>
              <form
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <div style={{ width: "70%" }}></div>
                  <div width="30%" className="unicircle_img "></div>
                </div>


                <div className="admin_dashboard">
                  <h1>
                    SuperAdmin
                    <br />
                    Dashboard
                  </h1>
                </div>
                <input
                  type="text"
                  id="reg_name"
                  placeholder="Username"
                  value={adminName}
                  onChange={(e) => updateAdminName(e.target.value)}
                  autoComplete="off"
                />
                <div
        class="name"
        style={{ margin: "0",  display:"none"}}
      >
        <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          Please Enter User Name
        </h4>
      </div>
                <input type="email" id="reg_email" placeholder="Email Address"
                 value={email}
                 onChange={(e) => updateEmail(e.target.value)}
                 />
                  <div
        class="email"
        style={{ margin: "0",  display:"none"}}
      >
        <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          Please Enter Email Address
        </h4>
      </div>
      <div
        class="validEmailAddress"
        style={{ margin: "0",  display:"none"}}
      >
        <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          Please Enter valid Email Address with @
        </h4>
      </div>
                <input type="number" id="reg_mobile" placeholder="Mobile"
                 value={mobile}
                 onChange={(e) => updateMobile(e.target.value)}
                 />
                  <div
        class="mobile"
        style={{ margin: "0",  display:"none"}}
      >
        <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          Please Enter Mobile Number
        </h4>
      </div>
                <input type="password" id="reg_password" placeholder="Create Password"
                 value={pass}
                 onChange={(e) => updatePass(e.target.value)}
                 />
                  <div
        class="pass_word"
        style={{ margin: "0",  display:"none"}}
      >
        <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          Please Enter Password
        </h4>
      </div>
                <div className="d-flex" style={{ marginTop: "20px" }}>
                  <div style={{ width: "70%" }}>
                  <div
        class="EnterValue"
        style={{ margin: "10px auto", display:"none" }}
      >

       <h4 class="login-text" style={{ color: "white",fontSize:"12PX",marginLeft:"0" }}>
          You Must Fill In All The Fields
        </h4>
      </div>
                  </div>
                  <div style={{ width: "30%" }}>
                    <input
                      type="button"
                      style={{
                        border: "1px solid #Fd4173",
                        background: "transparent",
                        fontWeight: "bold",
                        color: " #Fd4173",
                        margin: "0",
                        textAlign: "center",
                        padding: "5px 20px",
                      }}
                      id="go"
                      defaultValue="Sign In"
                      value="Register"
                      onClick={() => Register()}
                    />
                  </div>
                </div>

                <div class="myAlert-bottom">
        <div className="success_img ms-3"></div>
        <h4 class="login-text" style={{textAlign:"center",color:"green"}}>{message}</h4>
      </div>

              </form>
            </div>
            <div class="imgBx">
              <img
                src="dist/img/admin_login.png"
                className="img elevation text-center right_side_img"
                alt="User Image"
              />

              <div
                class="signup d-flex"
                style={{
                  color: "black",
                  marginTop: "5px",
                }}
              >
                <p style={{ marginTop: "20px", marginLeft: "auto" }}>
                  Already have an account ?
                </p>
                <a
                  href="#"
                  onClick={() => toggleForm()}
                  style={{ color: "black", marginLeft: "2px  " }}
                >
                  <button
                    style={{
                      background: "#Fd4173",
                      color: "white",
                      border: "none",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "10px",
                      margin: "10px 10px 0px 5px",

                      cursor: "pointer",

                      transition: "all 0.3s linear",
                    }}
                  >
                    Sign in
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}


    </div>
  );
}
