// import React, { useState } from "react";
// import { Header } from "../Header";
// import { Menu } from "../Menu";
// import { Job } from "./Job";

// export function JobDetails() {
//   const [loc, setLoc] = useState(window.location.pathname);
//   return (
//     <div >
//       <Header />

//       <div style={{ display: "flex" }}>
//       <Menu pageTitle={loc} />
//       <Job />
//     </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { Header } from "../Header";
import { Menu } from "../Menu";
import { Job } from "./Job";

export function JobDetails() {
  const [loc, setLoc] = useState(window.location.pathname);
  const [loading, setLoading] = useState(true); // Add loader state

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Disable loader after simulated delay
    }, 1000); // Adjust delay as needed

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  return (
    <div>
      <Header />
      <div style={{ display: "flex" }}>
        <Menu pageTitle={loc} />
        {loading ? (
          <div style={{ textAlign: "center", margin: "auto" }}>
            <p>Loading...</p> {/* Add your custom loader here */}
          </div>
        ) : (
          <Job />
        )}
      </div>
    </div>
  );
}


